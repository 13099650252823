<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div slot="header">
      <h5>{{ $route.meta.title }}</h5>
    </div>

    <el-form ref="elForm" :model="formData" :rules="rules" label-width="100px">
      <div class="row">
        <div class="col-md-8">
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="formData.title"
              placeholder="请输入标题"
              :maxlength="80"
              show-word-limit
              clearable
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="description">
            <el-input
              v-model="formData.description"
              type="textarea"
              placeholder="向观众介绍您的视频"
              :maxlength="200"
              show-word-limit
              :autosize="{ minRows: 4, maxRows: 4 }"
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label="视频类型" prop="is_original">
            <el-radio-group v-model="formData.is_original" size="medium">
              <el-radio-button
                v-for="(item, index) in is_originalOptions"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="所属频道" prop="category_id">
            <el-select
              v-model="formData.category_id"
              placeholder="请选择所属频道"
              clearable
              :style="{ width: '100%' }"
            >
              <el-option label=" " :value="0"></el-option>
              <el-option
                v-for="(item, index) in categoryOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
                :disabled="item.is_del > 0"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参与赛事" prop="race_id">
            <div
              class="d-flex justify-content-end"
              style="padding-top: 10px; line-height: 1.2"
            >
              <div class="w-100">
                <template v-if="formData.race">
                  <router-link
                    :to="{ path: '/race/detail/' + formData.race_id }"
                    target="_blank"
                  >
                    {{ formData.race.name }}
                  </router-link>
                </template>
                <template v-else> 未参与赛事 </template>
              </div>
              <div
                v-if="
                  !formData.race_id ||
                  (formData.race_id && !formData.create_time) ||
                  (formData.race_id &&
                    formData.create_time &&
                    $utils.time() - formData.create_time < 3600)
                "
                class="ml-20"
              >
                <el-button
                  type="info"
                  size="mini"
                  @click="raceDialogVisible = true"
                  >选择赛事</el-button
                >
              </div>
            </div>

            <div class="text-gray-6">
              注意：选定比赛活动1小时后将无法选择其他活动
            </div>
          </el-form-item>

          <div class="d-flex">
            <div
              class="text-right"
              style="width: 100px; padding-right: 12px; padding-top: 5px"
            >
              标签
            </div>
            <ul class="tag-list">
              <li class="mb-20" @click="tagDialogVisible = true">选择</li>
              <li class="mb-20" v-for="item in formData.tags" :key="item.id">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="d-flex">
            <div
              class="text-right"
              style="width: 100px; padding-right: 12px; padding-top: 5px"
            >
              更多属性
            </div>
            <el-switch
              v-model="value"
              active-color="#13ce66"
              inactive-color="#EAECF0"
              @change="toggleExtends()"
            >
            </el-switch>
            
          </div>
          <div v-if="seen" id="extends" class="d-flex">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="版权信息" name="first">
                <el-switch
                  v-model="copyright"
                  active-color="#13ce66"
                  inactive-color="#EAECF0"
                  active-text="开启版权保护"
                  inactive-text="关闭版权保护"
                 
                >
                </el-switch>
              </el-tab-pane>
              <el-tab-pane label="特征提取" name="second">
                <el-switch
                  v-model="feature"
                  active-color="#13ce66"
                  inactive-color="#EAECF0"
                  active-text="开启特征提取"
                  inactive-text="关闭特征提取"
                >
                </el-switch>
              </el-tab-pane>
              <el-tab-pane label="水印" name="third">
                <el-switch
                  v-model="watermark"
                  active-color="#13ce66"
                  inactive-color="#EAECF0"
                  active-text="开启水印"
                  inactive-text="关闭水印"
                >
                </el-switch>
              </el-tab-pane>
              <el-tab-pane label="授权访问" name="fourth">
                <el-switch
                  v-model="auth"
                  active-color="#13ce66"
                  inactive-color="#EAECF0"
                  active-text="开启授权访问"
                  inactive-text="关闭授权访问"
                >
                </el-switch>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="col-md-12">
          <video-player
            v-if="formData.video"
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
          >
          </video-player>
          <div
            class="upload-remind"
            v-if="!formData.video"
            @click="uploadDialogVisible = true"
          >
            <el-button type="primary">上传视频</el-button>
          </div>
          <h6 class="mt-10">视频封面图</h6>
          <div class="upload-remind mt-10" @click="coverDialogVisible = true">
            <el-button type="primary" v-if="!playerOptions.poster"
              >上传视频封面图</el-button
            >
            <img v-if="playerOptions.poster" :src="playerOptions.poster" />
          </div>

          <div class="mt-10" v-if="formData.id">
            视频地址：
            <div>
              <router-link
                class="small"
                :to="'/video/detail/' + formData.id"
                target="_blank"
              >
                {{ location }}/video/detail/{{ formData.id }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-20">
        <div class="col-md-8">
          <div class="text-right">
            <el-button v-if="formData.is_publish > 0" @click="submitForm(1)"
              >保存草稿</el-button
            >
            <el-button type="success" @click="submitForm(0)">发布</el-button>
          </div>
        </div>
      </div>
    </el-form>
    <el-dialog
      title="上传视频"
      :visible.sync="uploadDialogVisible"
      width="60%"
      append-to-body
    >
      <div class="d-flex justify-content-center">
        <VideoUploader @success="videoUploaded"></VideoUploader>
      </div>
    </el-dialog>

    <el-dialog
      title="上传视频封面"
      :visible.sync="coverDialogVisible"
      width="60%"
      append-to-body
    >
      <div class="d-flex justify-content-center align-items-center flex-column">
        <ImageCroper ref="imageCroper" @success="uploadImage"></ImageCroper>
        <el-button type="primary" @click="cropImage">上传</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="选择标签"
      :visible.sync="tagDialogVisible"
      width="60%"
      append-to-body
    >
      <div class="d-flex justify-content-center align-items-center flex-column">
        <ul class="tag-list">
          <li
            class="mb-20"
            :class="{ active: tagActive(item) }"
            v-for="item in tags"
            :key="item.id"
            @click="selectTag(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <el-button type="primary" @click="tagDialogVisible = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="选择赛事"
      :visible.sync="raceDialogVisible"
      width="60%"
      append-to-body
    >
      <RaceSelector @change="raceSelected"></RaceSelector>
    </el-dialog>
  </el-card>
</template>
<script>
import ImageCroper from "@/components/imageCroper";
import VideoUploader from "./components/VideoUploader";
import RaceSelector from "./components/RaceSelector";
export default {
  components: { VideoUploader, ImageCroper, RaceSelector },
  data() {
    return {
      seen: false,
      value: false,
      copyright: false,
      feature: false,
      watermark: false,
      auth: false,
      activeName: "first",
      location: window.location.protocol + "//" + window.location.host,
      uploadDialogVisible: false,
      playerOptions: {
        // videojs options
        width: 320,
        height: 180,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          /* {
            type: "video/mp4", //视频类型
            src: "" //播放地址
          } */
        ],
        poster: "", //封面
      },
      coverDialogVisible: false,
      formData: {
        title: "",
        video: "",
        thumb: [],
        description: "",
        is_original: 0,
        is_publish: 0,
        is_copyright: 0,
        is_feature: 0,
        is_watermark: 0,
        is_auth: 0,
        category_id: "",
        race_id: "",
        tags: [],
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
        description: [],
        is_original: [
          {
            required: true,
            message: "请选择视频类型",
            trigger: "change",
          },
        ],
        category_id: [
          {
            required: true,
            message: "请选择所属频道",
            trigger: "change",
          },
        ],
      },
      is_originalOptions: [
        {
          label: "原创",
          value: 0,
        },
        {
          label: "转载",
          value: 1,
        },
      ],
      categoryOptions: [],
      raceOptions: [],
      tagDialogVisible: false,
      tags: [],
      raceDialogVisible: false,
    };
  },
  computed: {
    
  },
  watch: {},
  created() {
    //频道
    this.$api.video.Category.lists({ showDel: 1 }).then((res) => {
      this.categoryOptions = res;
    });
    //标签
    this.$api.tags.Tags.lists({ showDel: 1 }).then((res) => {
      this.tags = res;
    });
    //比赛
    this.$api.race.Race.lists({ showDel: 1 }).then((res) => {
      this.raceOptions = res;
    });
  },
  mounted() {
    const id = this.$utils.toInt(this.$route.query.id);
    if (this.$route.query.id) {
      this.getDetail();
    } else {
      this.uploadDialogVisible = true;
    }
    const race_id = this.$utils.toInt(this.$route.query.race_id);
    console.log("race_id:", race_id);
    if (race_id && !id) {
      this.$api.race.Race.detail({
        id: race_id,
      }).then((res) => {
        const time = this.$utils.time();
        if (res.data.start_time > time) {
          this.$utils.error("比赛尚未开始");
          return;
        }
        if (time > res.data.end_time) {
          this.$utils.error("比赛已结束");
          return;
        }
        if (res.data.is_close > 0) {
          this.$utils.error("比赛已关闭");
          return;
        }
        this.formData.race_id = res.data.id;
        this.formData.race = res.data;
      });
    }
  },
  methods: {
    toggleExtends() {
      this.seen = !this.seen;
      return true;
    },
    //是否包含标签
    tagActive(item) {
      if (this.$utils.isEmpty(this.formData.tags)) {
        return false;
      }
      return this.formData.tags.some((row) => {
        return row.id === item.id;
      });
    },
    //选择标签
    selectTag(item) {
      if (this.tagActive(item)) {
        this.delTag(item);
      } else {
        this.formData.tags.push(item);
        this.formData.tags = [...new Set(this.formData.tags)];
      }
    },
    //删除标签
    delTag(item) {
      this.formData.tags.some((row, i) => {
        if (row.id === item.id) {
          this.$delete(this.formData.tags, i);
        }
      });
    },
    //选择赛事
    raceSelected(item) {
      this.formData.race_id = item.id;
      this.formData.race = item;
      this.raceDialogVisible = false;
    },
    videoUploaded(res) {
      //文件上传成功

      this.formData = res;

      if (
        this.$utils.isEmpty(this.formData.tags) ||
        !this.$utils.isArray(this.formData.tags)
      ) {
        this.formData.tags = [];
      }
      this.uploadDialogVisible = false;
      this.playerOptions.sources = [
        {
          type: "video/mp4", //视频类型
          src: this.viewImg(res.video), //播放地址
        },
      ];
      this.$message({
        message: "视频信息已保存到草稿箱，请完善其他信息",
        type: "success",
      });
    },
    cropImage() {
      this.$refs.imageCroper.crop();
    },
    uploadImage(file) {
      if (!file) {
        return;
      }
      let param = new FormData(); //创建form对象
      param.append("attach", file, "crop.jpg"); //通过append向form对象添加数据
      this.loading = true;
      this.$api.Upload.upload(param).then((res) => {
        this.formData.thumb = res.data;

        this.playerOptions.poster = this.viewImg(res.data.path);
        this.coverDialogVisible = false;
        this.loading = false;
      });
    },
    setFormData(data) {
      this.playerOptions.sources = [
        {
          type: "video/mp4", //视频类型
          src: this.viewImg(data.video), //播放地址
        },
      ];

      this.playerOptions.poster = this.viewImg(data.thumbData.path);
      this.formData = data;
      if (
        this.$utils.isEmpty(this.formData.tags) ||
        !this.$utils.isArray(this.formData.tags)
      ) {
        this.formData.tags = [];
      }
      this.formData.thumb = data.thumbData;
    },
    getDetail() {
      this.$api.video.Video.detail({ id: this.$route.query.id })
        .then((res) => {
          console.log("res data start:", res.data);
          let video = res.data.video;

          let searchTerm = 'encrypt';
          let indexOf = video.indexOf(searchTerm);
          if (indexOf > -1) {
            video = video.substr(0, indexOf);
            video = video + "/" + "origin.mp4";
            res.data.video = video;
          }

          searchTerm = 'watermark';
          indexOf = video.indexOf(searchTerm);
          if (indexOf > -1) {
            video = video.substr(0, indexOf);
            video = video + "/" + "origin.mp4";
            res.data.video = video;
          }

          this.setFormData(res.data);

          console.log("res data end:", res.data);
          
          if(res.data.is_copyright == 1){
            this.copyright = true;
          }else{
            this.copyright = false;
          }

          if(res.data.is_feature == 1){
            this.feature = true;
          }else{
            this.feature = false;
          }

          if(res.data.is_watermark == 1){
            this.watermark = true;
          }else{
            this.watermark = false;
          }

          if(res.data.is_auth == 1){
            this.auth = true;
          }else{
            this.auth = false;
          }

        })
        .catch(() => {
          this.loading = false;
        });
    },
    submitForm(is_publish) {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        if (this.$utils.isEmpty(this.formData.video)) {
          this.$utils.error("请上传视频");
          return false;
        }
        if (this.$utils.isEmpty(this.formData.thumb)) {
          this.$utils.error("请上传视频封面");
          return false;
        }
        
        if(this.copyright == false){
          this.formData.is_copyright = 0;
        }else{
          this.formData.is_copyright = 1;
        }

        if(this.feature == false){
          this.formData.is_feature = 0;
        }else{
          this.formData.is_feature = 1;
        }

        if(this.watermark == false){
          this.formData.is_watermark = 0;
        }else{
          this.formData.is_watermark = 1;
        }

        if(this.auth == false){
          this.formData.is_auth = 0;
        }else{
          this.formData.is_auth = 1;
        }

        this.formData.is_publish = is_publish;
        // TODO 提交表单
        this.loading = true;
        this.$api.ucenter.Video.save(this.formData)
          .then((res) => {
            this.loading = false;
            console.log("res.data=="+res.data);
            this.setFormData(res.data);
            if(res.data.is_watermark == 1){
              this.$utils.success(is_publish ? "保存成功" : "正在生成水印，水印生成完成后会自动发布");
            }else{
              if(res.data.is_auth == 1){
                console.log("publish is_auth=="+res.data.is_auth)
                this.$utils.success(is_publish ? "保存成功" : "正在进行加密授权，完成后会自动发布");
              }else{
                console.log("publish is_auth=="+res.data.is_auth)
                this.$utils.success(is_publish ? "保存成功" : "发布成功");
              }
            }
            
          })
          .catch((err) => {
            console.log("err:", err);
            this.loading = false;
          });
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-remind {
  width: 320px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  cursor: pointer;
  img {
    max-width: 100%;
  }
}
</style>
