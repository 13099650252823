<template>
  <div>
 

      <div
        v-for="item in lists"
        :key="'item' + item.id"
       
        class="block flex-fill"
        
      >
        <img
          v-if="item.thumb"
          :src="viewThumb(item.thumbData.thumb, 'medium')"
          class="thumb"
        />

        <div class="block-body">
          <div class="block-body-title">
               <router-link
     
        :to="{ path: '/race/detail/' + item.id }"
     
        target="_blank"
      >
            {{ item.name }}
               </router-link>
          </div>
          <div class="block-body-extend">
            <div>
              {{ item.startTime }}至{{ item.endTime }}
              <span class="text-info">{{ raceStatus(item) }}</span>
            </div>
            <div>
              {{ item.video }}
              <span class="text-gray-6 mr-20">参赛</span>

              <template
                v-if="
                  item.is_close < 1 &&
                  $utils.time() > item.start_time &&
                  $utils.time() < item.end_time
                "
              >
                <el-button type="primary" size="mini" @click="selectItem(item)">选择</el-button>
                
              </template>
            </div>
          </div>
          <div class="block-body-desc">
            {{ item.description }}
          </div>
          <div class="block-body-desc">
            <span> {{ item.createDate }} </span>
            <span> {{ $utils.contrastTime(item.create_time) }}</span>
          </div>
        </div>
      </div>

      <div class="text-center mt-20" v-if="params.pages > 1">
        <el-pagination
          @size-change="pageSizeChange"
          @current-change="pageChange"
          :current-page="params.page"
          :page-size="params.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="params.total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      metaTitle: "",
      loading: false,
      lists: [],
      searchTimer: null, //搜索计时器
      params: {
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },

  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.$api.race.Race.lists(this.params)
        .then((res) => {
          console.log("res:", res);
          this.loading = false;
          this.lists = res.data.lists;

          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch((err) => {
          console.log("err:", err);
          this.loading = false;
        });
    },

    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },
    selectItem(item) {
      this.$emit("change", item);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>