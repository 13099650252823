<template>
  <!-- 上传组件 -->
  <el-upload
    action
    drag
    :auto-upload="false"
    :show-file-list="false"
    :on-change="handleChange"
    accept=".mp3,.mp4,video/mp4,video/ogg,video/webm,.webm,.ogv"
  >
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">
      将文件拖到此处，或<em>点击上传</em>
      <div v-if="percent > 0">{{ percent.toFixed() }}%</div>
    </div>
    <div class="el-upload__tip" slot="tip">
      <p>只能上{{ allowType.join("、") }}格式媒体文件。</p>
      <p>您的媒体在发布之前将处于私享状态。</p>
    </div>
  </el-upload>

  <!-- 进度显示 -->

  <!--      <el-button type="primary" size="mini" @click="handleClickBtn">{{
        upload | btnTextFilter
      }}</el-button> -->
</template>

<script>
import SparkMD5 from "spark-md5";
export default {
  name: "VideoUploader",
  filters: {
    btnTextFilter(val) {
      return val ? "暂停" : "继续";
    },
  },
  data() {
    return {
      title: "",
      percent: 0,
      videoUrl: "",
      upload: true,
      percentCount: 0,
      chunkList: [],
      hash: "",
      fileName: "",
      allowType: [
        ".wav",
        ".mp3",
        ".wma",
        ".mp4",
        ".flv",
        ".avi",
        ".wmv",
        ".mov",
        ".webm",
        ".mpeg4",
      ],
    };
  },
  mounted() {},
  methods: {
    async handleChange(file) {
      //检查登录状态
      this.$axios.get("/api/front/user/user/getUserInfo").then(async (res) => {
        if (!file) return;
        this.title = file.name.substring(0, file.name.indexOf("."));
        this.fileName = file.name;
        this.percent = 0;
        this.videoUrl = "";

        const fileObj = file.raw;

        // 将文件按固定大小（2M）进行切片，注意此处同时声明了多个常量 2097152
        const chunkSize = 2097152,
          chunkList = [], // 保存所有切片的数组
          chunkListLength = Math.ceil(fileObj.size / chunkSize), // 计算总共多个切片
          suffix = /\.([0-9A-z]+)$/.exec(fileObj.name)[1]; // 文件后缀名

        if (!this.allowType.includes("." + suffix)) {
          this.$message.error("不支持的文件类型");
          return;
        }

        // 获取文件并转成 ArrayBuffer 对象
        let buffer;
        try {
          buffer = await this.fileToBuffer(fileObj);
        } catch (e) {
          console.log(e);
        }

        // 根据文件内容生成 hash 值
        const spark = new SparkMD5.ArrayBuffer();
        spark.append(buffer);
        //给hash加3个随机字符,避免重复传1个文件ffmpeg命令出错
        // const hash = spark.end();
        const hash = spark.end()+Math.floor(Math.random() * 1000);
        console.log("hash:", hash);

        // 生成切片，这里后端要求传递的参数为字节数据块（chunk）和每个数据块的文件名（fileName）
        let curChunk = 0; // 切片时的初始位置
        for (let i = 0; i < chunkListLength; i++) {
          const item = {
            chunk: fileObj.slice(curChunk, curChunk + chunkSize),
            fileName: `${hash}_${i}.${suffix}`, // 文件名规则按照 hash_1.jpg 命名
          };
          curChunk += chunkSize;
          chunkList.push(item);
        }
        this.chunkList = chunkList; // sendRequest 要用到
        this.hash = hash; // sendRequest 要用到

        this.sendRequest();
      });
    },

    // 发送请求
    sendRequest() {
      const requestList = []; // 请求集合
      this.chunkList.forEach((item, index) => {
        const fn = () => {
          const formData = new FormData();

          formData.append("filename", item.fileName);
          formData.append("hash", this.hash);
          formData.append("chunk", item.chunk, item.fileName);
          return this.$axios
            .post("/api/front/uploadVideo", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              console.log("res:", res);
              // 成功
              if (this.percentCount === 0) {
                // 避免上传成功后会删除切片改变 chunkList 的长度影响到 percentCount 的值
                this.percentCount = 100 / this.chunkList.length;
              }
              this.percent += this.percentCount; // 改变进度
              this.chunkList.splice(index, 1); // 一旦上传成功就删除这一个 chunk，方便断点续传
            });
        };
        requestList.push(fn);
      });

      let i = 0; // 记录发送的请求个数
      // 文件切片全部发送完毕后，需要请求 '/merge' 接口，把文件的 hash 传递给服务器
      const complete = () => {
        console.log("this.hash", this.hash);
        this.$axios
          .get("/api/front/uploadVideo/merge", {
            params: {
              hash: this.hash,
              title: this.title,
              fileName: this.fileName,
            },
          })
          .then((res) => {
            console.log("upload success");
            this.$emit("success", res.data);
          });
      };
      const send = async () => {
        if (!this.upload) return;
        if (i >= requestList.length) {
          // 发送完毕
          complete();
          return;
        }
        await requestList[i]();
        i++;
        send();
      };
      send(); // 发送请求
    },

    // 按下暂停按钮
    handleClickBtn() {
      this.upload = !this.upload;
      // 如果不暂停则继续上传
      if (this.upload) this.sendRequest();
    },

    // 将 File 对象转为 ArrayBuffer
    fileToBuffer(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = (e) => {
          resolve(e.target.result);
        };
        fr.readAsArrayBuffer(file);
        fr.onerror = () => {
          reject(new Error("转换文件格式发生错误"));
        };
      });
    },
  },
};
</script>
